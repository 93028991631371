import React from "react";
import TablePagination from "@mui/material/TablePagination";

export default function Pagination({ 
  count, 
  rowsPerPage, 
  page,
  onPageChange,
  onRowsPerPageChange
}) {  

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      sx={{
        ".MuiTablePagination-toolbar": {
          alignItems: "baseline",
          minHeight: "20px"
        },
      }}
    />
  );
}
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { NODE_ADDRESS, NODE_ADDRESS_TEST } from '../blockchain/NodeAddress/NodeAddress';
import "../../assets/css/style.css";

export default function NodeDropdown() {
  const [nodeAddress, setNodeAddress] = useState("");

  const handleChange = (event) => {
    const changedNodeAddress = event.target.value;
    setNodeAddress(changedNodeAddress);
    localStorage.setItem("nodeAddress", changedNodeAddress);
  };

  useEffect(() => {
    const storedNode = localStorage.getItem("nodeAddress");
    console.log(storedNode, nodeAddress)
    if (!storedNode) {
      setNodeAddress(NODE_ADDRESS);
      localStorage.setItem("nodeAddress", NODE_ADDRESS);
    } else setNodeAddress(storedNode)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <Box>
      <FormControl fullWidth variant="standard">
        <Select
          labelId="Node-Address"
          id="Node-Address"
          value={nodeAddress}
          label="Node Address"
          onChange={handleChange}
          disableUnderline
        >
          <MenuItem value={NODE_ADDRESS}>Mainnet</MenuItem>
          <MenuItem value={NODE_ADDRESS_TEST}>Testnet</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
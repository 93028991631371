import React, { useState, useMemo } from "react";
import { 
  FormControl, 
  OutlinedInput,
  Grid,
} from "@material-ui/core";

import "../../../../assets/css/style.css";
import MediumModal from "../../../../components/Modals/MediumModal";
import { leave } from "../../../../components/blockchain/OwlsNest/OwlsNestClient";

export default function Withdraw({ open, handleClose, activePublicKey }) {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [isConfirmPage, setIsConfirmPage] = useState(false);

  const closeModal = () => {
    setIsConfirmPage(false);
    handleClose();
  };

  const withdraw = async () => {
    try {
      // @TODO: get max amount in contract
      const result = await leave(activePublicKey, withdrawAmount);
      console.log("withdraw result", result)
    } catch(err) {
      console.log(err)
    }
  }

  const handleButton = () => {
    isConfirmPage
      ? withdraw()
      : setIsConfirmPage(true);
  };

  const buttonText = useMemo(() => {
    if (!activePublicKey) return "Sign in";
    return isConfirmPage 
      ? "Withdraw & Claim" 
      : "Continue";
  }, [activePublicKey, isConfirmPage]);

  return (
    <MediumModal 
      handleClose={closeModal} 
      open={open} 
      title="Withdraw"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: isConfirmPage ? "none" : "flex" }}>
          Available tokens: 0 OSNOWL
        </Grid>
        <Grid 
          item 
          container
          className="grid-center"
          spacing={2}
          style={{ display: isConfirmPage ? "flex" : "none" }}
        >
          <Grid item xs={12} className="info-text-xl grid-center">
            0.00
          </Grid>
          <Grid item xs={12}>
              OSNOWL
          </Grid>
          <Grid item xs={12} className="info-text-xl grid-center">
            0.00
          </Grid>
          <Grid item xs={12}>
              SNOWL
          </Grid>
        </Grid>
        <Grid 
          style={{ display: isConfirmPage ? "none" : "flex" }}
          item xs={12} 
          className="grid-right"
        >
          <FormControl
            variant="outlined"
            fullWidth
          >
            <OutlinedInput
              step="any"
              type="number"
              min={0}
              id="outlined-adornment-weight"
              value={withdrawAmount}
              onChange={(e) => { setWithdrawAmount(e.target.value) }}
              endAdornment={
                <button className="adornment-btn" position="end">
                  MAX
                </button>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className="grid-center">
          When you withdraw, your o-token is claimed and your dex tokens are burned
        </Grid>
        <Grid item xs={12}>
          <button 
            disabled={!activePublicKey}
            onClick={handleButton} 
            className="modal-button-white blue"
          >
            { buttonText }
          </button>
        </Grid>
      </Grid>
    </MediumModal>
  );
}
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "../../../../assets/css/bootstrap.min.css";
import "../../../../assets/css/style.css";
import "../../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import HeaderHome from "../../../../components/Headers/Header";
// import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// import Tooltip from "@mui/material/Tooltip";
// import { None } from "ts-results";

import { 
  // descendingComparator,
  getComparator,
  stableSort
 } from "../../../../assets/js/utils";
import { EnhancedTableHead, EnhancedTableToolbar } from "../../../../components/MUI/TableHead";
import Pagination from "../../../../components/MUI/Pagination";

const headCells = [
  {
    id: "#",
    alignment: "right",
    disablePadding: false,
    label: "#",
  },
  // {
  //   id: "nameA/B",
  //   alignment: false,
  //   disablePadding: false,
  //   label: "Name A/B",
  // },
  {
    id: "token0Symbol",
    alignment: "left",
    disablePadding: false,
    label: "Pairing",
  },
  {
    id: "id",
    alignment: "left",
    disablePadding: false,
    label: "Contract Hash",
  },
  {
    id: "reserve0",
    alignment: "right",
    disablePadding: false,
    label: "Reserve A",
  },
  {
    id: "reserve1",
    alignment: "right",
    disablePadding: false,
    label: "Reserve B",
  },
  {
    id: "reserveETH",
    alignment: "right",
    disablePadding: false,
    label: "CSPR Reserve",
  },
  {
    id: "reserveUSD",
    alignment: "right",
    disablePadding: false,
    label: "ReserveUSD",
  },
];

function Pairs(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("NameA/B");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // const [openRowIndex, setOpenRowIndex] = useState(false);
  const [pairList, setPairList] = useState([]);
  const [isPairList, setIsPairList] = useState(false);
  let [, setActivePublicKey] = useState(localStorage.getItem("Address"));
  let [selectedWallet, setSelectedWallet] = useState(
    localStorage.getItem("selectedWallet")
  );
  let [, setTorus] = useState();
  useEffect(() => {
    setIsPairList(true);
    axios
      .get("/getpairlist")
      .then((res) => {
        console.log("resresres", res);
        for (let i = 0; i < res.data.pairList.length; i++) {
          res.data.pairList[i].token0Name = res.data.pairList[i].token0.name
          res.data.pairList[i].token0Symbol = res.data.pairList[i].token0.symbol
          res.data.pairList[i].token0Id = res.data.pairList[i].token0.id
          res.data.pairList[i].token1Name = res.data.pairList[i].token1.name
          res.data.pairList[i].token1Symbol = res.data.pairList[i].token1.symbol
          res.data.pairList[i].token1Id = res.data.pairList[i].token1.id
        }
        setIsPairList(false);
        console.log(res.data.pairList);
        setPairList(res.data.pairList);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        setIsPairList(false);
      }); // eslint-disable-next-line
  }, []);
  function shortenAddress(address, chars = 15) {
    return `${address.substring(0, chars + 2)}...${address.substring(
      64 - chars
    )}`;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const handleSetRowIndex = (index) => {
  //   const indexVal = index === openRowIndex ? null : index;
  //   setOpenRowIndex(indexVal)
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pairList.length) : 0;

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="home-section home-full-height">
          <HeaderHome
            setActivePublicKey={setActivePublicKey}
            setSelectedWallet={setSelectedWallet}
            selectedWallet={selectedWallet}
            setTorus={setTorus}
            selectedNav={"pairs"}
          />
          <div style={{ backgroundColor: "#000052" }} className="card">
            <div className="container-fluid">
              <div
                className="content"
                style={{ paddingTop: "180px", height: "150vh" }}
                position="absolute"
              >
                <div className="card" style={{ borderRadius: '8px' }}>
                  <Typography
                    style={{ marginLeft: "15px", marginTop: "15px" }}
                    variant="h5"
                    color="textSecondary"
                    component="p"
                  >
                    <strong></strong>
                  </Typography>
                  <div className="container-fluid">
                    <div
                      className="row"
                      style={{
                        height: `${props.windowHeight}`,
                        marginRight: "px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                          <EnhancedTableToolbar title="Token Pairings" />
                          <TableContainer>
                            {isPairList ? (
                              <div
                                style={{ padding: "20px" }}
                                className="row align-items-center justify-content-center"
                              >
                                <Spinner
                                  style={{
                                    textAlign: "center",
                                    color: "#6476bf",
                                  }}
                                  animation="border"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              </div>
                            ) : (
                              <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                              >
                                <EnhancedTableHead
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={handleRequestSort}
                                  headCells={headCells}
                                />
                                <TableBody>
                                  {stableSort(
                                    pairList,
                                    getComparator(order, orderBy)
                                  )
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                      const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={index}
                                        >
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align="right"
                                          >
                                            {index + 1}
                                          </TableCell>
                                          {/* <TableCell align="left">
                                            {row.token0.name}/{row.token1.name}
                                          </TableCell> */}
                                          <TableCell align="left">
                                            {row.token0Symbol}-
                                            {row.token1Symbol}
                                          </TableCell>
                                          <TableCell align="left">
                                            {shortenAddress(row.id)} {' '}
                                            <OpenInNewIcon />
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.reserve0 / 10 ** 9}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.reserve1 / 10 ** 9}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.reserveETH / 10 ** 9}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.reserveUSD / 10 ** 9}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  {emptyRows > 0 && (
                                    <TableRow
                                      style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                      }}
                                    >
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            )}
                          </TableContainer>
                          <Pagination
                            count={pairList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={dense}
                              onChange={handleChangeDense}
                            />
                          }
                          label="Dense padding"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pairs;

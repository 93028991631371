import React, { useState } from "react";
import { 
  FormControl, 
  OutlinedInput,
  Grid,
} from "@material-ui/core";

import "../../../../assets/css/style.css";
import MediumModal from "../../../../components/Modals/MediumModal";
import { enter, approve } from "../../../../components/blockchain/OwlsNest/OwlsNestClient";

export default function Deposit({ open, handleClose, activePublicKey }) {
  const [depositAmount, setDepositAmount] = useState("");

  const deposit = async () => {
    try {
      const result = await enter(activePublicKey, depositAmount);
      console.log("deposit result", result)
    } catch(err) {
      console.log(err)
    }
  };

  const approveDeposit = async () => {
    try {
      const result = await approve(activePublicKey, depositAmount);
      console.log("approve result", result)
    } catch(err) {
      console.log(err)
    }
  };

  return (
    <MediumModal 
      handleClose={handleClose} 
      open={open} 
      title="Deposit"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} className="grid-right">
          Available to deposit: 0 CSPR
        </Grid>
        <Grid item xs={12} className="grid-right">
        <FormControl
          variant="outlined"
          fullWidth
        >
          <OutlinedInput
            step="any"
            type="number"
            min={0}
            id="outlined-adornment-weight"
            value={depositAmount}
            onChange={(e) => { setDepositAmount(e.target.value) }}
            endAdornment={
              <button className="adornment-btn" position="end">
                MAX
              </button> 
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl> 
        </Grid>
        <Grid item xs={12} className="grid-space-between">
          <div>
            Rate
          </div>
          <div>
            0000
          </div>
        </Grid>
        <Grid item xs={6}>
          <button 
            disabled={!activePublicKey}
            onClick={approveDeposit} 
            className="modal-button-white blue"
          >
            { activePublicKey ? "Approve" : "Sign in" }
          </button>
        </Grid>
        <Grid item xs={6}>
          <button 
            disabled={!activePublicKey}
            onClick={deposit} 
            className="modal-button-white blue"
          >
            { activePublicKey ? "Deposit" : "Sign in" }
          </button>
        </Grid>
        <Grid item container spacing={0}>
          <Grid item xs={6}>
            <div className="step-lines">
              <span className="steps">
                1
              </span>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="step-lines">
              <span className="steps">
                2
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </MediumModal>
  );
}
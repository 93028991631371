import {
  CLByteArray,
  CLValue,
  CLValueBuilder,
  RuntimeArgs,
  CasperServiceByJsonRPC
} from "casper-js-sdk";

import { NODE_ADDRESS } from "../NodeAddress/NodeAddress";
import { getStateRootHash } from "../GetStateRootHash/GetStateRootHash";

import { 
  camelCased,
  getContractData,
  contractSimpleGetter,
  contractCall
} from "../utils";

const PAYMENT_AMOUNT = 5000000000;

export const enter = async (
  activePublicKey,
  amount,
  paymentAmount = PAYMENT_AMOUNT, // @TODO: get proper payment amount
) => {

  const runtimeArgs = RuntimeArgs.fromMap({
    snowl_amount: CLValueBuilder.u256(amount)
  });

  const deployHash = await contractCall({
    entryPoint: "enter",
    activePublicKey,
    paymentAmount,
    runtimeArgs,
  });

  if (deployHash !== null) {
    
    return deployHash;
  } else {
    throw Error("Invalid Deploy");
  }
};

export const leave = async (
  activePublicKey,
  amount,
  paymentAmount = PAYMENT_AMOUNT,
) => {
  const runtimeArgs = RuntimeArgs.fromMap({
    o_snowl_amount: CLValueBuilder.u256(amount)
  });

  const deployHash = await contractCall({
    entryPoint: "leave",
    activePublicKey,
    paymentAmount,
    runtimeArgs,
  });

  if (deployHash !== null) {
    
    return deployHash;
  } else {
    throw Error("Invalid Deploy");
  }
};

export const approve = async (
  activePublicKey,
  amount = PAYMENT_AMOUNT,
  paymentAmount = PAYMENT_AMOUNT,
) => {
  // spender is active public key
  const _spender = new CLByteArray(
    Uint8Array.from(Buffer.from(activePublicKey, "hex"))
  );

  const runtimeArgs = RuntimeArgs.fromMap({
    _spender,
    amount: CLValueBuilder.u256(amount)
  });


  const deployHash = await contractCall({
    entryPoint: "approve",
    activePublicKey,
    paymentAmount,
    runtimeArgs,
  });

  if (deployHash !== null) {
    
    return deployHash;
  } else {
    throw Error("Invalid Deploy");
  }
};

export const contractDictionaryGetter = async (
  accountHash,
  seedUref,
) => {
  const stateRootHash = await getStateRootHash(NODE_ADDRESS);
  const client = new CasperServiceByJsonRPC(NODE_ADDRESS);

  const storedValue = await client.getDictionaryItemByURef(
    stateRootHash,
    accountHash,
    seedUref
  );

  if (storedValue && storedValue.CLValue instanceof CLValue) {
    return storedValue.CLValue;
  } else {
    throw Error("Invalid stored value");
  }
};

export const balanceOf = async (account) => {
  try {

    const contractData = await getContractData();
    // @TODO: refactor to state (redux?)
    const { namedKeys } = contractData.Contract;
    const LIST_OF_NAMED_KEYS = [
      'balances',
      'self_erc20',
      'nonces',
      'allowances'
    ]
    const formattedKeys = namedKeys.reduce((acc, val) => {
      if (LIST_OF_NAMED_KEYS.includes(val.name)) {
        return { ...acc, [camelCased(val.name)]: val.key };
      }
      return acc;
    }, {});

    const result = await contractDictionaryGetter(
      account,
      formattedKeys.balances
    );
    const maybeValue = result.value().unwrap();
    return maybeValue.value().toString();

  } catch (error) {
    console.log("osnowl bal err", error)
    return "0";
  }
}

export const getTotalSupply = async () => {
  const result = await contractSimpleGetter(["total_supply"]);
  return result.value();
}
import React from 'react';
import Typography from '@mui/material/Typography';
import { Modal } from "react-bootstrap";

import "../../assets/css/bootstrap.css";
import Exit from "../../assets/img/exit.svg";

export default function MediumModal({ open, handleClose, children, title }) {
  return (
      <Modal
        centered
        show={open}
        onHide={handleClose}
        aria-labelledby="medium-modal"
      >
        <Modal.Body>
          <Typography style={{ fontSize: '20px' }} gutterBottom>
            <strong>
              { title }
            </strong>
            <span
              onClick={handleClose}
              style={{
                float: 'right',
                cursor: 'pointer',
                position: 'relative',
                bottom: '10px'
              }}
            >
              <img
                src={Exit}
                alt="exit"
                width="15"
              />
            </span>
          </Typography>
          { children }
        </Modal.Body>
      </Modal>
  );
}
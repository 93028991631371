import { Typography } from "@material-ui/core";
import React from "react";
import { Alert, Modal } from "react-bootstrap";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import { BLOCK_EXPLORER_BASE_URL } from "../../assets/js/utils";

function SuccessModal({
  tokenA,
  amountTokenA,
  tokenB,
  amountTokenB,
  successHash,
  onClose
}) {
    return (
        <Modal centered show={!!successHash}>
          <Modal.Body style={{ textAlign: 'center' }}>
            <Typography variant="h5" style={{ color: '#000027' }} gutterBottom ><strong>Success!</strong></Typography>
            <div className="row align-items-center justify-content-center">
              <strong>
                You have successfully traded { amountTokenA } { tokenA?.symbol } for { amountTokenB } { tokenB?.symbol }
              </strong>
            </div>
            <Alert color="light" style={{ color: '#000027' }}>
              <a target="_blank" rel="noopener noreferrer" href={BLOCK_EXPLORER_BASE_URL + "deploy/" + successHash}>
                <strong style={{ color: '#000027' }}>See transaction on block explorer</strong>
              </a>
            </Alert>
            <button className="modal-button-white" onClick={() => onClose(false)}>
              Make another swap
            </button>
          </Modal.Body>
        </Modal>

    );
}

export default SuccessModal;

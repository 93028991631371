import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";

import "../../../../assets/css/bootstrap.min.css";
import "../../../../assets/css/style.css";
import "../../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import HeaderHome from "../../../../components/Headers/Header";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import { balanceOf, getTotalSupply } from "../../../../components/blockchain/OwlsNest/OwlsNestClient";

function OwlsNest(props) {
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [activePublicKey, setActivePublicKey] = useState(localStorage.getItem("Address"));
  const [selectedWallet, setSelectedWallet] = useState(
    localStorage.getItem("selectedWallet")
  );
  const [, setTorus] = useState();

  useEffect(() => {
    const getBalance = async () => {
      try {
        const balance = await balanceOf(activePublicKey);
        console.log('snowlbalance', balance);
        // @TODO: set state
      } catch(err) {
        console.log(err)
      }
    }
    if (activePublicKey) getBalance();
  }, [activePublicKey]);

  useEffect(() => {
    const getSupply = async () => {
      try {
        const res = await getTotalSupply()
        console.log("TOTAL SUP", res.toString())
      } catch(err) {
        console.log(err);
      }
    }
    getSupply();
  }, []);

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="home-section home-full-height">
          <HeaderHome
            setActivePublicKey={setActivePublicKey}
            setSelectedWallet={setSelectedWallet}
            selectedWallet={selectedWallet}
            setTorus={setTorus}
            selectedNav={"owls-nest"}
          />
          <div style={{ backgroundColor: "#000052" }} className="card">
            <div
              className="content"
              style={{ paddingTop: "180px", height: "150vh" }}
              position="absolute"
            >
              <div className="container-fluid">
                <div
                  className="row"
                  style={{
                    height: `${props.windowHeight}`,
                    marginRight: "px",
                  }}
                >
                  <div className="col-md-10 offset-md-1">
                      <div className="account-content">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-md-12 col-lg-6 login-right">
                            <div className="login-header">
                              <h3>
                                <div
                                  style={{
                                    textAlign: "center",
                                    color: "#08209e",
                                  }}
                                >
                                  <Typography variant="h5" style={{ color: "#000027" }} gutterBottom >
                                    <strong>
                                      Owl's Nest
                                    </strong>
                                  </Typography>
                                </div>
                              </h3>
                            </div>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <div className="info-box">
                                  Your SNOWL Balance
                                  <div className="info-text-md">
                                    0.00
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={6}>
                                <div className="info-box">
                                  Your Rate
                                  <div className="info-text-md">
                                    0.00
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className="info-box">
                                  Your OSNOWL Balance
                                  <div className="info-text-lg">
                                    0.00
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                  <div className="card-footer-text">
                                    When you withdraw, the contract will automatically claim SNOWL on your behalf!
                                  </div>
                              </Grid>
                              <Grid item xs={6}>
                                <button onClick={() => setOpenWithdraw(true)} className="modal-button-white">
                                  Withdraw
                                </button>
                              </Grid>
                              <Grid item xs={6}>
                                <button onClick={() => setOpenDeposit(true)} className="modal-button-white blue">
                                  Deposit
                                </button>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography style={{ color: "black", display: "flex", justifyContent: "center" }}>
                                  0 tokens available
                                </Typography>
                              </Grid>
                            </Grid>
                            <Deposit 
                              handleClose={() => setOpenDeposit(false)} 
                              open={openDeposit} 
                              activePublicKey={activePublicKey}
                            />
                            <Withdraw 
                              handleClose={() => setOpenWithdraw(false)} 
                              open={openWithdraw} 
                              activePublicKey={activePublicKey}
                            />
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwlsNest;

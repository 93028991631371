import { Avatar } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { CLPublicKey } from "casper-js-sdk";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import Pagination from "../../../components/MUI/Pagination";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import HeaderHome from "../../../components/Headers/Header";
// import { LineChart } from "../../../assets/js/chart";
import {  getComparator, stableSort } from "../../../assets/js/utils";
import { EnhancedTableHead, EnhancedTableToolbar } from "../../../components/MUI/TableHead";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginLeft: 0,
  },
}));

const headCells = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "#",
  },
  {
    id: "logoURI",
    numeric: false,
    disablePadding: false,
    label: "Logo",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  {
    id: "liquidity",
    numeric: false,
    disablePadding: false,
    label: "Liquidity",
  },
  {
    id: "Volume",
    numeric: false,
    disablePadding: false,
    label: "Volume (24hrs)", 
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "priceChange",
    numeric: false,
    disablePadding: false,
    label: "Price Change (24hrs)",
  },
  // {
  //   id: "address",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Contract Hash",
  // },
  // {
  //   id: "packageHash",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Package Hash",
  // },
  {
    id: "balance",
    numeric: false,
    disablePadding: false,
    label: "My Balance",
  },
];

function Analytics(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  const [tokenList, setTokenList] = useState([]);
  const [istokenList, setIsTokenList] = useState(false);
  // eslint-disable-next-line
  let [activePublicKey, setActivePublicKey] = useState(
    localStorage.getItem("Address")
  );
  // eslint-disable-next-line
  let [selectedWallet, setSelectedWallet] = useState(
    localStorage.getItem("selectedWallet")
  );
  let [, setTorus] = useState();

  useEffect(() => {
    axios
      .get("/tokensList")
      .then(async (res) => {
        console.log("tokensList", res);
        console.log(res.data.tokens);
        let holdArr = res.data.tokens;
        console.log("holdArr", holdArr);
        if (
          activePublicKey !== "null" &&
          activePublicKey !== null &&
          activePublicKey !== undefined
        ) {
          for (let i = 0; i < holdArr.length; i++) {
            let param = {
              contractHash: holdArr[i].contractHash.slice(5),
              user: Buffer.from(
                CLPublicKey.fromHex(activePublicKey).toAccountHash()
              ).toString("hex"),
            };
            await axios
              .post("/balanceagainstuser", param)
              .then((res) => {
                console.log("balanceagainstuser", res);
                console.log(res.data);
                holdArr[i].balance = res.data.balance;
                // setTokenBBalance(res.data.balance)
              })
              .catch((error) => {
                holdArr[i].balance = 0;
                console.log(error);
                console.log(error.response);
              });

              // await axios
              // .post("/graphql/tokenDayDatas", param)
              // .then((res) => {
              //   console.log("tokenDayData", res);
              //   // setTokenBBalance(res.data.balance)
              // })
              // .catch((error) => {
              //   holdArr[i].balance = 0;
              //   console.log(error);
              //   console.log(error.response);
              // });
          }
        }
        // holdArr.splice(0, 0, CSPR)
        console.log("holdArr", holdArr);
        setTokenList(res.data.tokens);
        setIsTokenList(true);
        // setTokenList(oldArray => [...oldArray, CSPR])
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  }, [activePublicKey]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tokenList.length) : 0;

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="home-section home-full-height">
          <HeaderHome
            setActivePublicKey={setActivePublicKey}
            setSelectedWallet={setSelectedWallet}
            selectedWallet={selectedWallet}
            setTorus={setTorus}
            selectedNav={"Analytics"}
          />
          <div style={{ backgroundColor: "#000052" }} className="card">
            <div className="container-fluid">
              <div
                className="content"
                style={{ paddingTop: "180px", height: "150vh" }}
                position="absolute"
              >
                {/* <div
                  className="row row-sm"
                  style={{
                    height: `${props.windowHeight}`,
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                    <Paper sx={{ width: "49%", mb: 2 }}>
                      <div className="table-container">
                        <div style={{ marginBottom: "20px" }}>
                          <div className="table-title-text">
                            TVL
                          </div>
                          <div className="table-info-text">
                            $0
                          </div>
                        </div>
                        <div>
                          <LineChart />
                        </div>
                      </div>
                    </Paper>
                    <Paper sx={{ width: "49%", mb: 2 }}>
                      <div className="table-container">
                        <div style={{ marginBottom: "20px" }}>
                          <div className="table-title-text">
                            Volume 24h
                          </div>
                          <div className="table-info-text">
                            $0
                          </div>
                        </div>
                        <div>
                          <LineChart />
                        </div>
                      </div>
                    </Paper>
                </div> */}
                <div className="card" style={{ borderRadius: '8px' }}>
                  <div className="container-fluid">
                    <div
                      className="row"
                      style={{
                        height: `${props.windowHeight}`,
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                          <EnhancedTableToolbar title="Tokens" />
                          <TableContainer>
                            {!istokenList ? (
                              <div
                                style={{ padding: "20px" }}
                                className="row align-items-center justify-content-center"
                              >
                                <Spinner
                                  style={{
                                    textAlign: "center",
                                    color: "#6476bf",
                                  }}
                                  animation="border"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              </div>
                            ) : (
                              <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                              >
                                <EnhancedTableHead
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={handleRequestSort}
                                  headCells={headCells}
                                />
                                <TableBody>
                                  {stableSort(
                                    tokenList,
                                    getComparator(order, orderBy)
                                  )
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                      const labelId = `enhanced-table-checkbox-${index}`;

                                      return (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={index}
                                        >
                                          <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align="right"
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell align="right">
                                            <Avatar
                                              src={row.logoURI}
                                              aria-label="Token"
                                              className={classes.avatar}
                                            />
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.name}
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.symbol}
                                          </TableCell>
                                          <TableCell align="left">
                                            ###
                                          </TableCell>
                                          <TableCell align="left">
                                            $___,___.__
                                          </TableCell>
                                          <TableCell align="left">
                                            $__.___
                                          </TableCell>
                                          <TableCell align="left">
                                            __.___%
                                          </TableCell>
                                          {/* <TableCell align="left">
                                            {shortenAddress(row.address)}
                                          </TableCell>
                                          <TableCell align="left">
                                            {shortenAddress(row.packageHash)}
                                          </TableCell> */}
                                          <TableCell align="left">
                                            {row.balance
                                              ? row.balance / 10 ** 9
                                              : 0}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  {emptyRows > 0 && (
                                    <TableRow
                                      style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                      }}
                                    >
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            )}
                          </TableContainer>
                          <Pagination
                            count={tokenList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={dense}
                              onChange={handleChangeDense}
                            />
                          }
                          label="Dense padding"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;

import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "../../../../assets/css/bootstrap.min.css";
import "../../../../assets/css/style.css";
import "../../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import HeaderHome from "../../../../components/Headers/Header";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Pagination from "../../../../components/MUI/Pagination";
import ClaimModal from "./ClaimModal";
import TableDropdown from "./TableDropdown";
import { 
  BLOCK_EXPLORER_URL,
  getComparator,
  stableSort
 } from "../../../../assets/js/utils";
import { EnhancedTableHead, EnhancedTableToolbar } from "../../../../components/MUI/TableHead";

const ODD_ROW_COLOR = "rgba(235, 235, 235, 0.4)";
const EVEN_ROW_COLOR = "white";

const headCells = [
  {
    id: "#",
    alignment: "left",
    disablePadding: false,
    label: "#",
  },
  {
    id: "token0Symbol",
    alignment: "left",
    disablePadding: false,
    label: "Pairing",
  },
  {
    id: "claim",
    alignment: "right",
    disablePadding: false,
    label: "",
  },
  {
    id: "contract-Address",
    alignment: "center",
    disablePadding: false,
    label: "Contract Address",
  },
  {
    id: "reserve0",
    alignment: "left",
    disablePadding: false,
    label: "My Staked",
  },
  {
    id: "reserve1",
    alignment: "left",
    disablePadding: false,
    label: "APR",
  },
  {
    id: "Total Staked",
    alignment: "left",
    disablePadding: false,
    label: "Total Staked",
  },
    {
    id: "dropdown",
    numeric: "center",
    disablePadding: false,
    label: "",
  },
];

function Farms(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("NameA/B");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(false);
  const [pairList, setPairList] = useState([]);
  const [isPairList, setIsPairList] = useState(false);
  let [, setActivePublicKey] = useState(localStorage.getItem("Address"));
  let [selectedWallet, setSelectedWallet] = useState(
    localStorage.getItem("selectedWallet")
  );
  let [, setTorus] = useState();

  // @TODO: still using "dummy" data from pairs for this table
  useEffect(() => {
    setIsPairList(true);
    axios
      .get("/getpairlist")
      .then((res) => {
        console.log("resresres", res);
        for (let i = 0; i < res.data.pairList.length; i++) {
          res.data.pairList[i].token0Name = res.data.pairList[i].token0.name
          res.data.pairList[i].token0Symbol = res.data.pairList[i].token0.symbol
          res.data.pairList[i].token0Id = res.data.pairList[i].token0.id
          res.data.pairList[i].token1Name = res.data.pairList[i].token1.name
          res.data.pairList[i].token1Symbol = res.data.pairList[i].token1.symbol
          res.data.pairList[i].token1Id = res.data.pairList[i].token1.id
        }
        setIsPairList(false);
        console.log(res.data.pairList);
        setPairList(res.data.pairList);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        setIsPairList(false);
      }); // eslint-disable-next-line
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSetRowIndex = (index) => {
    const indexVal = index === openRowIndex ? null : index;
    setOpenRowIndex(indexVal)
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pairList.length) : 0;

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="home-section home-full-height">
          <HeaderHome
            setActivePublicKey={setActivePublicKey}
            setSelectedWallet={setSelectedWallet}
            selectedWallet={selectedWallet}
            setTorus={setTorus}
            selectedNav={"Farms"}
          />
          <div style={{ backgroundColor: "#000052" }} className="card">
            <div className="container-fluid">
              <div
                className="content"
                style={{ paddingTop: "180px", height: "150vh" }}
                position="absolute"
              >
                <div className="card" style={{ borderRadius: '8px' }}>
                  <Typography
                    style={{ marginLeft: "15px", marginTop: "15px" }}
                    variant="h5"
                    color="textSecondary"
                    component="p"
                  >
                    <strong></strong>
                  </Typography>
                  <div className="container-fluid">
                    <div
                      className="row"
                      style={{
                        height: `${props.windowHeight}`,
                        marginRight: "px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                          <EnhancedTableToolbar title="Farms" />
                          <TableContainer>
                            {isPairList ? (
                              <div
                                style={{ padding: "20px" }}
                                className="row align-items-center justify-content-center"
                              >
                                <Spinner
                                  style={{
                                    textAlign: "center",
                                    color: "#6476bf",
                                  }}
                                  animation="border"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              </div>
                            ) : (
                              <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                                className="table-striped"
                              >
                                <EnhancedTableHead
                                  order={order}
                                  orderBy={orderBy}
                                  onRequestSort={handleRequestSort}
                                  headCells={headCells}
                                />
                                <TableBody>
                                  {stableSort(
                                    pairList,
                                    getComparator(order, orderBy)
                                  )
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                      const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <React.Fragment key={index + "f"}>
                                          <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            style={{ 
                                              backgroundColor: index % 2 === 0 ? ODD_ROW_COLOR : EVEN_ROW_COLOR 
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              id={labelId}
                                              scope="row"
                                              alight="left"
                                            >
                                              {index + 1}
                                            </TableCell>
                                            {/* <TableCell align="left">
                                              {row.token0.name}/{row.token1.name}
                                            </TableCell> */}
                                            <TableCell align="left">
                                              {row.token0Symbol}/
                                              {row.token1Symbol}
                                            </TableCell>
                                            <TableCell align="right">
                                              <button 
                                                onClick={() => setOpenClaimModal(true)} 
                                                className="table-claim-button"
                                              >
                                                Claim
                                              </button>
                                            </TableCell>
                                            <TableCell align="center">
                                              <a 
                                                style={{ color: "black" }}
                                                href={`${BLOCK_EXPLORER_URL}${row.id}`} 
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <OpenInNewIcon />
                                              </a>
                                            </TableCell>
                                            <TableCell alight="left">
                                              {row.reserve0 / 10 ** 9}
                                            </TableCell>
                                            <TableCell alight="left">
                                              {row.reserve1 / 10 ** 9}
                                            </TableCell>
                                            <TableCell alight="left">
                                              {row.reserveETH / 10 ** 9}
                                            </TableCell>
                                            {/* <TableCell alight="left">
                                              {row.reserveUSD / 10 ** 9}
                                            </TableCell> */}
                                            <TableCell alight="center">
                                              <button 
                                                onClick={() => handleSetRowIndex(index)} 
                                                className="icon-button"
                                              >
                                                { index === openRowIndex
                                                  ? <KeyboardArrowUpIcon />
                                                  : <KeyboardArrowDownIcon /> }
                                              </button>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow 
                                            key={index + "c"}
                                            style={{ 
                                              backgroundColor: index % 2 === 0 ? ODD_ROW_COLOR : EVEN_ROW_COLOR 
                                            }}
                                          >
                                            <TableCell 
                                              colSpan={12}
                                              style={{ 
                                                paddingBottom: 0, 
                                                paddingTop: 0,
                                                border: "none"
                                              }}
                                            >
                                              <TableDropdown openRowIndex={openRowIndex} index={index} />
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      );
                                    })}
                                  {emptyRows > 0 && (
                                    <TableRow
                                      style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                      }}
                                    >
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            )}
                          </TableContainer>
                          <Pagination
                            count={pairList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={dense}
                              onChange={handleChangeDense}
                            />
                          }
                          label="Dense padding"
                        />
                      </Box>
                    </div>
                    <ClaimModal 
                      open={openClaimModal} 
                      handleClose={() => setOpenClaimModal(false)} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Farms;

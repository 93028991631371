import React, { useState } from "react";
import { 
  FormControl, 
  OutlinedInput,
  Grid,
  Box,
  Collapse
} from "@material-ui/core";

import "../../../../assets/css/style.css";

export default function TableDropdown({ openRowIndex, index }) {
  const [stakeAmount, setStakeAmount] = useState("");

  return (
    <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>
      <Box className="collapse-row">
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
          <Grid item xs={2}>
            <div className="dropdown-text-lg" style={{ marginBottom: "5px" }}>
              Amount to stake
            </div>
            <div className="dropdown-text-md" style={{ marginTop: "5px" }}>
              Available LP tokens: 000
            </div>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <OutlinedInput
                step="any"
                type="number"
                min={0}
                id="outlined-adornment-weight"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                endAdornment={
                  <button className="adornment-btn-small" position="end">
                    MAX
                  </button> 
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                style={{ height: "40px" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <button onClick={() => console.log("dep")} className="modal-button-white blue">
              Unstake
            </button>
          </Grid>
          <Grid item xs={2}>
            <button onClick={() => console.log("dep")} className="modal-button-white blue">
              Stake
            </button>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
}
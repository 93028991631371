import {
  CLPublicKey,
  CLValue,
  DeployUtil,
  CasperServiceByJsonRPC
} from "casper-js-sdk";

import { NODE_ADDRESS } from "./NodeAddress/NodeAddress";
import { DRAGONS_LAIR_CONTRACT_HASH } from "./AccountHashes/Addresses";
import { signdeploywithcaspersigner } from './SignDeploy/SignDeploy';
import { getStateRootHash } from "./GetStateRootHash/GetStateRootHash";
import { putdeploy } from './PutDeploy/PutDeploy';

const CHAIN_NAME = "casper-test";

export const contractHashToByteArray = (contractHash) => (
  Uint8Array.from(Buffer.from(contractHash, "hex"))
);

export const camelCased = (string) =>
  string.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

export const getContractData = async (contractHash = DRAGONS_LAIR_CONTRACT_HASH, path = []) => {
  const client = new CasperServiceByJsonRPC(NODE_ADDRESS);
  const stateRootHash = await getStateRootHash(NODE_ADDRESS);
  const blockState = await client.getBlockState(
    stateRootHash,
    `hash-${contractHash}`,
    path
  );
  return blockState;
};

export const contractSimpleGetter = async (path) => {
  const clValue = await getContractData(path);

  if (clValue && clValue.CLValue instanceof CLValue) {
    return clValue.CLValue;
  } else {
    throw Error("Invalid stored value");
  }
};

export const contractCall = async ({
  activePublicKey,
  entryPoint,
  runtimeArgs,
  paymentAmount,
  contractHash = DRAGONS_LAIR_CONTRACT_HASH,
}) => {
  // const client = new CasperClient(NODE_ADDRESS);
  const contractHashAsByteArray = contractHashToByteArray(contractHash);
  const publicKey = CLPublicKey.fromHex(activePublicKey);

  let deploy = DeployUtil.makeDeploy(
    new DeployUtil.DeployParams(publicKey, CHAIN_NAME),
    DeployUtil.ExecutableDeployItem.newStoredContractByHash(
      contractHashAsByteArray,
      entryPoint,
      runtimeArgs
    ),
    DeployUtil.standardPayment(paymentAmount)
  );

  let signedDeploy = await signdeploywithcaspersigner(
    deploy,
    activePublicKey
  );
  const result = await putdeploy(signedDeploy); // add snackbar
  console.log("result", result);
  return result;
};
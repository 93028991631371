export const FACTORY_CONTRACT_TEST="0b2a418c929b206afacd1211495b06e956d8bee641a7218ef60a6b1fe76782d6"
export const FACTORY_CONTRACT_PACKAGE_TEST="6545e0bc7d034c96bd8966a28902368b75d1557c4ef8a4ff325e0388519e7823"
export const ROUTER_CONTRACT_HASH_TEST="96a2000cff0e5d23c230036c7504f1a742e5cf4da11c4a6f23b1410d3e80af4a"
export const ROUTER_PACKAGE_HASH_TEST="722f1938300b2f7a1c6b183203ecd84775c036027d6f7e40a409ec8f49763721"
export const DRAGONS_LAIR_CONTRACT_HASH = "0b5505e1705b0033c686c54a8d42506f39558a4782c7a7b453a2ab570a0d6677"
export const DRAGONS_LAIR_PACKAGE_HASH = "097d03cf68021ec0c1289d619161294ddeff71723789c17ebde8dfe73315052f"
export const SNOWL_CONTRACT= "ce37a9e2707774d236076bf81ccb9270613eddc8f0af144ae05ec74477c12704"
export const SNOWL_PACKAGE= "a55cd56e940549d311c0715e6281489e3b508c8c0b7fa519ef51ed116ad7c87f"

// MAIN
export const FACTORY_CONTRACT="3181756dbba580256a0ff5fc362a83401741f18fec683d28f0ce56d8ff02a655"
export const FACTORY_CONTRACT_PACKAGE="d18a2e16feb21a71921d8612f8db823d81a0a0e9010c71990ba688df7f34cd76"
export const ROUTER_CONTRACT_HASH="c39483cea3b0d33a9c1e2a92eb610fc89d3815734d4e55859bfa5d6d65f52dcf"
export const ROUTER_PACKAGE_HASH="714aaeb1677970aad331ae192a0e2d127ba2379f2052231254420f9e440b9ab6"

export const BLOCK_EXPLORER_URL = "https://cspr.live/account/";
export const BLOCK_EXPLORER_BASE_URL = "https://cspr.live/";
export const BLOCK_EXPLORER_URL_TESTNET = "https://testnet.cspr.live/deploy/";

export const descendingComparator = (a, b, orderBy) => {
  console.log("a", a[orderBy]);
  console.log("b", b);
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const getComparator = (order, orderBy) => {
  console.log("order, orderBy", order, orderBy);
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
  // console.log("array",array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    // console.log("a",a);
    // console.log("b",b);
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const shortenAddress = (address, chars = 15) => {
  return `${address.substring(0, chars + 2)}...${address.substring(
    64 - chars
  )}`;
};

export const checkToken = (props, mappedToken) => {
  if (!props.TokenA) return false;
  if (props.tokenA.name === mappedToken.name) return true;
  const selectedWCSPR = props.tokenA.symbol === "WCSPR" && mappedToken.symbol === "CSPR";
  const selectedCSPR = props.tokenA.symbol === "CSPR" && mappedToken.symbol === "WCSPR";
  return selectedWCSPR || selectedCSPR;
};
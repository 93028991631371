import React from "react";
import { Grid } from "@material-ui/core";

import "../../../../assets/css/style.css";
import MediumModal from "../../../../components/Modals/MediumModal";

export default function ClaimModal({ open, handleClose }) {

  const closeModal = () => {
    handleClose();
  };

  return (
    <MediumModal 
      handleClose={closeModal} 
      open={open} 
      title="Claim Amount"
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          Available tokens: 0
        </Grid>
        <Grid item xs={12}>
          <button onClick={() => console.log('claim')} className="modal-button-white blue">
            Claim
          </button>
        </Grid>
      </Grid>
    </MediumModal>
  );
}